import './style.scss'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
// import * as dat from 'dat.gui'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
var countriesToFR = ""; //countries transaltion to french

import gsap from 'gsap'
import { EquirectangularReflectionMapping } from 'three'


//language (EN or FR)----
var hash = window.location.hash.substr(1);
var cities;
function setLang(lang) {
    if (lang == '') {
        var language = window.navigator.userLanguage || window.navigator.language;
        if (language.includes("fr"))
            lang = 'fr'
    }
    if (lang == 'fr') {
        //id="h1_title">Cities of earth 2050
        writeTextById('h1_title', 'Les villes du monde, 2050')
        //Climate like
        writeTextById('l_like', 'Climat similaire')
        //id="l_temp_month">Temperature of warmest month
        writeTextById('l_temp_month', 'Température du mois le plus chaud')
        //id="l_temp_ann">Annual Temperature</h3>
        writeTextById('l_temp_ann', 'Température annuelle')
        //FR
        writeTextById('lang', 'EN')
        document.getElementById("lang").href = "javascript:toggleLang('en')";
        //Designed & made by Aurélien
        writeTextById('made_by', 'Fait par Aurélien Berçon')
        //Data from Cities Future
        writeTextById('data_from', 'Données de Cities Future')

        // countriesToFR = require('./countriesToFR.json');
        cities = require('./citiesFR.json');

    }else{
        cities = require('./cities.json');
    }
}
function setCountryLang(countryEN) {
    let currentCountry = countryEN
    if (countriesToFR != "") {
        //is FR
        countriesToFR.every(country => {
            if (country.en == countryEN)
            {
                currentCountry = country.fr;
                return false;
            }
            return true;
        })
    }
    return currentCountry
}
setLang(hash);
//end language-----

//3D object loader
const gltfLoader = new GLTFLoader()

// Debug
// const gui = new dat.GUI()

// Canvas
const canvas = document.querySelector('canvas.webgl')



// Scene
const scene = new THREE.Scene()

//in mobile/table or not:
function checkIsTouchDevice() {
    return (('ontouchstart' in window) ||
        (navigator.maxTouchPoints > 0) ||
        (navigator.msMaxTouchPoints > 0));
}
const isTouchDevice = checkIsTouchDevice();

//backgroud

new THREE.TextureLoader().load("starmap4k_blue.jpg", function (texture) {
    texture.mapping = EquirectangularReflectionMapping
    scene.background = texture
    scene.environment = texture

})

/**
 * Line
 */

let lastPosStart = 0;
let isLineDrawn = false;
let IsLargeSphere = true; //false is very zoomed in


const lineMaterial = new THREE.LineBasicMaterial({ color: 0xffffff });
const lineGeometry = new THREE.BufferGeometry();
function drawLine(posStart) {

    if (typeof posStart !== 'undefined') {
        lastPosStart = posStart;
    }
    else {
        //undefined = means we animated the last point, the first doesn't change
        posStart = lastPosStart;
    }
    removeLine();//remove existing line (if any)

    const points = [];
    points.push(posStart);
    points.push(getEndPoint());

    lineGeometry.setFromPoints(points);
    lineGeometry.setDrawRange(0, 2);

    const line = new THREE.Line(lineGeometry, lineMaterial);
    addSphere(posStart, false, IsLargeSphere);

    line.name = "selectorline"
    scene.add(line);



    isLineDrawn = true;
}

function addSphere(pos, ishover, isLarge = true) {
    // transparent sphere 
    var geometry;
    if (isLarge)
        geometry = new THREE.SphereGeometry(.03, 8, 8);
    else
        geometry = new THREE.SphereGeometry(.015, 12, 8);

    const color = ishover ? 0x7CFF75 : 0xffffff;
    const material = new THREE.MeshBasicMaterial({ color: color });
    material.transparent = true;
    material.opacity = 0.4;
    const sphere = new THREE.Mesh(geometry, material);
    sphere.position.set(pos.x, pos.y, pos.z);
    sphere.name = ishover ? "hoverSphere" : "selectorSphere"
    scene.add(sphere);
}

function removeHoverSphere() {
    if (scene.getObjectByName('hoverSphere') !== undefined) {
        scene.remove(scene.getObjectByName('hoverSphere'));
    }
}

function removeLine() {
    if (scene.getObjectByName('selectorline') !== undefined) {
        scene.remove(scene.getObjectByName('selectorline'));
    }

    if (scene.getObjectByName('selectorSphere') !== undefined) {
        scene.remove(scene.getObjectByName('selectorSphere'));
    }

    // lastPosStart = 0;
}

function getEndPoint() {
    //vectorFinal : end point
    let elem = document.querySelector('.inner-city-window');
    let rect = elem.getBoundingClientRect(); //get absolute position
    let px = ((rect['x'] + 136) / sizes.width) * 2 - 1; // +1: ajust //136: move line in the middle of the window to avoid end the line going outside
    let py = - ((rect['y'] + 136) / sizes.height) * 2 + 1; // +44: ajust to bottom of the shape
    var vectorFinal = new THREE.Vector3(px, py, -1).unproject(camera); //convert 2d position screen to 3d position

    return vectorFinal;
}


/**
 * Points
 */


// Objects
// const geometry = new THREE.CircleGeometry( .1, 16 )
const geometry = new THREE.SphereGeometry(.01, 16, 8)

// Materials

const material = new THREE.MeshStandardMaterial()
material.color = new THREE.Color(0xff0000)

/**
 * InstancedMesh
 */

const dummy = new THREE.Object3D()
const InGeometry = new THREE.SphereBufferGeometry(.02, 16, 12)
const InMaterial = new THREE.MeshBasicMaterial()
InMaterial.transparent = true;
InMaterial.opacity = 0.8;
const Incolor = new THREE.Color(0x7CFF75)
const InCount = cities.length
const InMesh = new THREE.InstancedMesh(InGeometry, InMaterial, InCount)
scene.add(InMesh)

function setInMesh() {
    for (let i = 0; i < InCount; i++) {
        dummy.position.set(cities[i].x, cities[i].y, cities[i].z)
        dummy.updateMatrix()
        InMesh.setMatrixAt(i, dummy.matrix)
        InMesh.setColorAt(i, Incolor);
    }
}





setInMesh();

/**
 * Earth
 */


//timeline
let tl = gsap.timeline()


//earth

let startSize = 0.8;
let EndSize = 1;

let obj;

gltfLoader.load('earth_clean.gltf', (gltf) => {
    //scale object
    obj = gltf.scene
    obj.scale.set(startSize, startSize, startSize)
    //rotate object
    obj.rotation.set(0, 4.71, 0)

    scene.add(obj)

    tl.to('#loader', { opacity: 0, duration: 1 })
    tl.to(controls, { autoRotate: true })
    tl.to('.overlay.active', { backgroundColor: "rgba(0, 10, 16, 0)", duration: 1 }, "-=.3")
    tl.to(obj.scale, { x: EndSize, y: EndSize, z: EndSize, duration: 1 }, "-=1") //-=1: offset
    tl.to('h1', { opacity: 0, duration: 1 }, "+=.5")
    tl.to(controls, { autoRotate: false })
    tl.to('.overlay.active', { className: "overlay" })
    tl.to('h1', { opacity: 1, duration: 1 })
    tl.to('.footer', { opacity: 1, duration: 1 }, "-=1")

},
    // called while loading is progressing
    function (xhr) {
        var txt = (xhr.loaded / xhr.total * 100).toFixed(0) + '%';
        let element = document.getElementById("loaded")
        element.innerHTML = txt;

    })

// Lights

const pointLight1 = new THREE.PointLight(0xffffff, 2)
pointLight1.position.set(3.58, 2.26, 3.05)
pointLight1.intensity = 1.74
pointLight1.castShadow = true
scene.add(pointLight1)

// lights debug
// const gLight1 = gui.addFolder("Light 1")
// gLight1.add(pointLight1.position, 'x').min(-6).max(6).step(0.01)
// gLight1.add(pointLight1.position, 'y').min(-6).max(6).step(0.01)
// gLight1.add(pointLight1.position, 'z').min(-6).max(6).step(0.01)
// gLight1.add(pointLight1, 'intensity').min(0).max(10).step(0.01)

const pointLight2 = new THREE.PointLight(0x1c719d, 2)
pointLight2.position.set(-0.92, -1.32, 0.16)
pointLight2.intensity = 5.5

scene.add(pointLight2)

// // lights debug
// const gLight2 = gui.addFolder("Light 2")
// gLight2.add(pointLight2.position, 'x').min(-6).max(6).step(0.01)
// gLight2.add(pointLight2.position, 'y').min(-6).max(6).step(0.01)
// gLight2.add(pointLight2.position, 'z').min(-6).max(6).step(0.01)
// gLight2.add(pointLight2, 'intensity').min(0).max(10).step(0.01)



const pointLight13 = new THREE.PointLight(0xffffff, 2)
pointLight13.position.set(-0.92, -1.3, 3.18)
pointLight13.intensity = 2.74
scene.add(pointLight13)

const pointLight4 = new THREE.PointLight(0xffffff, 2)
pointLight4.position.set(-2.37, 0.14, 0.67)
pointLight4.intensity = 3.74
scene.add(pointLight4)



const ambientLight = new THREE.AmbientLight(0x404040, 10); // soft white light
// ambientLight.position.set(-0.92,-1.3,3.18)
scene.add(ambientLight);

// // lights debug
// const gambientLight = gui.addFolder("ambient Light")
// gambientLight.add(ambientLight, 'intensity').min(0).max(20).step(0.01)



/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () => {
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
// camera.position.x = 0.3
// camera.position.y = 0.2
// camera.position.z = 1.2
camera.position.x = 1.19
camera.position.y = 0.14
camera.position.z = 0
scene.add(camera)

// // cam debug
// const gCam = gui.addFolder("Camera")
// gCam.add(camera.position, 'x').min(-6).max(6).step(0.001)
// gCam.add(camera.position, 'y').min(-6).max(6).step(0.001)
// gCam.add(camera.position, 'z').min(-6).max(6).step(0.001)

// Controls
const controls = new OrbitControls(camera, canvas)
controls.target.set(0, 0, 0);
controls.enablePan = false;
controls.minDistance = .8;
controls.maxDistance = 50;
controls.enableDamping = true;
controls.enableZoom = true;
controls.update();
// controls.autoRotate = true;
// setTimeout(function () {
//     controls.autoRotate = false;

// }, 1000)


/**
* Interaction
*/



const raycaster = new THREE.Raycaster();
const pointer = new THREE.Vector2();

function onPointerMove(event) {
    // calculate pointer position in normalized device coordinates
    // (-1 to +1) for both components
    // console.log(event.clientX );

    pointer.x = (event.clientX / window.innerWidth) * 2 - 1;
    pointer.y = - (event.clientY / window.innerHeight) * 2 + 1;


}
function onTargetTouches(event) {

    // calculate pointer position in normalized device coordinates
    // (-1 to +1) for both components

    var datatouch;
    let istouchValid = false;

    if (typeof event.targetTouches[0] !== 'undefined') {
        istouchValid = true;
        datatouch = event.targetTouches[0];
    } else {
        if (typeof event.changedTouches[0] !== 'undefined') {
            istouchValid = true;
            datatouch = event.changedTouches[0];
        }
    }


    if (istouchValid) {
        pointer.x = +(datatouch.pageX / window.innerWidth) * 2 - 1;
        pointer.y = -(datatouch.pageY / window.innerHeight) * 2 + 1;
    } else {
        alert('YOU GOT ME ;) \nI am just a designer: I did not manage all the devices, please check on your computer Firefox or Chrome for a better experience')
    }


    setInteraction();
    onDocumentClick(event)

}

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    alpha: true,
    antialias: true
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
renderer.shadowMap.enabled = true
// renderer.toneMapping = THREE.ACESFilmicToneMapping
// renderer.toneMappingExposure = 1
// renderer.outputEncoding = THREE.sRGBEncoding
// renderer.shadowMap.type = THREE.PCFShadowMap;

/**
 * Animate
 */

document.addEventListener('mousemove', onDocumentMouseMove)

let mouseX = 0
let mouseY = 0


const windowHalfX = window.innerWidth / 2;
const windowHalfY = window.innerHeight / 2;

function onDocumentMouseMove(event) {
    mouseX = (event.clientX - windowHalfX)
    mouseY = (event.clientY - windowHalfY)
}

let IsMesh = false //IsMesh: if the click in the point
let instanceId = -1
let PrevId = -1
let isCityActive = false

document.addEventListener('click', onDocumentClick)
// document.addEventListener('touchend', onDocumentClick)

function onDocumentClick(event) {

    if (IsMesh) {
        setWindow(instanceId);
        if (PrevId !== -1)
            InMesh.setColorAt(PrevId, Incolor); //reset last selected (if any)

        InMesh.setColorAt(instanceId, white);
        InMesh.instanceColor.needsUpdate = true;
        isCityActive = true
        drawLine(new THREE.Vector3(cities[instanceId].x, cities[instanceId].y, cities[instanceId].z));
        IsMesh = false
        PrevId = instanceId
    } else {
        //if not orbiting
        if (!isCtrUpdating) {
            InMesh.setColorAt(instanceId, Incolor);
            InMesh.instanceColor.needsUpdate = true;
            isCityActive = false;
            removeLine();
            windowVisible(false);

        }
    }
}


function setWindow(cityId) {
    windowVisible(true);
    let element = document.getElementById("l_like")
    element.classList.add("txt");

    writeTextById("c_name", cities[cityId].name);
    writeTextById("c_country", setCountryLang(cities[cityId].country));
    writeTextById("like", cities[cityId].like);
    writeTextById("country_like", setCountryLang(cities[cityId].countryLike));
    let ta = cities[cityId].tempAnnual;
    setBackgroundColor(ta, "temp_ann_box");
    writeTextById("temp_ann", formatTemp(ta));
    let tm = cities[cityId].tempMonth;
    setBackgroundColor(tm, "temp_month_box");
    writeTextById("temp_month", formatTemp(tm));
}

function windowVisible(show) {
    const window = document.getElementById("window");
    if (show)
        window.classList.remove("hidden");
    else
        window.classList.add("hidden");

}





function writeTextById(id, txt) {
    let element = document.getElementById(id)
    element.innerHTML = txt;
    element.classList.add("txt_transition");
    setTimeout(function () {
        element.classList.remove("txt_transition");
    }, 600)
}

function formatTemp(temp) {
    if (temp >= 0) {
        return "+" + temp.toFixed(1) + "°C";
    } else {
        return temp + "°C";
    }
}

function setBackgroundColor(temp, id) {
    const background = document.getElementById(id);
    background.classList.remove("warning")
    background.classList.remove("error")
    if (temp > 0.8 && temp < 3) {
        background.classList.add("warning");
    } else {
        if (temp >= 3) {
            background.classList.add("error");
        }
    }

}
let displayedName = false
let prevId = -1
//mouse hover
const hoverContainer = document.getElementById('hoverCity');
function displayCityName(id, isHover) {
    if (id !== prevId || !displayedName) {
        removeHoverSphere(); //if id !== prevId : name already displayed
        prevId = id
        if (isHover) {
            hoverContainer.classList.remove("hidden")
            writeTextById('hoverCity', cities[id].name);
            addSphere(new THREE.Vector3(cities[instanceId].x, cities[instanceId].y, cities[instanceId].z), true, IsLargeSphere);
            displayedName = true
        }
    }

}
function hideCityName(id, isHover) {
    if (!isHover && displayedName) {
        removeHoverSphere();
        hoverContainer.classList.add("hidden")
        displayedName = false
    }
    // else

}

function setSphereSizeWithDis(updis) {
    let isClose = (updis < 0.88);
    if (isClose && IsLargeSphere) {
        const InGeometry2 = new THREE.SphereBufferGeometry(.01, 16, 12)
        InMesh.geometry = InGeometry2;
        IsLargeSphere = false;
    } else {
        if (!isClose) {
            InMesh.geometry = InGeometry;
            IsLargeSphere = true;
        }
    }
}


//const clock = new THREE.Clock()

const color = new THREE.Color();
const white = new THREE.Color().setHex(0xffffff);
let distance = controls.object.position.distanceTo(controls.target).toFixed(3);
let isCtrUpdating = false;
let updis = 0;
const tick = () => {


    //const elapsedTime = clock.getElapsedTime()

    // Update objects
    //sphere.rotation.y = .5 * elapsedTime

    // Update Orbital Controls

    isCtrUpdating = controls.update()

    //update line while camera orbiting
    if (isCityActive && isCtrUpdating) {
        if (lastPosStart !== 0)
            drawLine(lastPosStart)
    }

    updis = controls.object.position.distanceTo(controls.target).toFixed(3);


    setSphereSizeWithDis(updis)

    // //update line while camera zooming
    if (isCityActive && distance !== updis) {
        if (lastPosStart !== 0)
            drawLine(lastPosStart)
        distance = updis;
    }

    if (!isTouchDevice)
        setInteraction();


    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

function setInteraction() {
    //**interaction**
    // update the picking ray with the camera and pointer position
    raycaster.setFromCamera(pointer, camera);
    // calculate objects intersecting the picking ray
    const intersection = raycaster.intersectObject(InMesh);

    if (intersection.length > 0) {
        instanceId = intersection[0].instanceId;
        IsMesh = true
        if (!isTouchDevice) {
            document.body.style.cursor = 'pointer'
            displayCityName(instanceId, IsMesh)
        }

    } else {
        IsMesh = false
        if (!isTouchDevice) {
            document.body.style.cursor = 'default'
            hideCityName(instanceId, IsMesh)
        }
    }
}

tick()






//interaction
if (isTouchDevice) {
    window.addEventListener('touchend', onTargetTouches);
} else {
    window.addEventListener('pointermove', onPointerMove);
}
// window.requestAnimationFrame(render);
